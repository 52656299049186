@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  scroll-behavior: smooth;
}

.gradient-01 {
  background-image: linear-gradient(
    270deg,
    hsl(295deg 76% 51%) 0%,
    hsl(284deg 70% 73%) 26%,
    hsl(257deg 70% 86%) 39%,
    hsl(202deg 92% 90%) 50%,
    hsl(215deg 77% 81%) 61%,
    hsl(221deg 73% 70%) 74%,
    hsl(220deg 76% 51%) 100%
  );
  filter: blur(125px);
}

.hero-gradient {
  background: linear-gradient(
    97.86deg,
    #a509ff 0%,
    #34acc7 53.65%,
    #a134c7 100%
  );
}

.gradient-02 {
  position: absolute;
  width: 200px;
  height: 438px;
  top: 0px;
  right: 0px;

  background: #7aebfb;
  filter: blur(190px);
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gradient-03 {
  position: absolute;
  width: 404px;
  height: 800px;
  left: 20%;
  top: 5%;

  background: rgba(149, 66, 232, 0.35);
  filter: blur(175px);
  transform: rotate(-114.2deg);
}

.gradient-04 {
  position: absolute;
  width: 304px;
  height: 100vh;
  left: 30%;
  top: 10%;

  background: rgba(45, 72, 152, 0.75);
  filter: blur(200px);
  transform: rotate(-53.13deg);
}

.gradient-05 {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.04) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.feedback-gradient {
  position: absolute;
  width: 200px;
  height: 316px;
  left: 0px;
  top: 0px;

  background: #8d39a3;
  filter: blur(175px);
}

.footer-gradient {
  position: absolute;
  /* width: 500px; */
  height: 100px;
  left: 37%;
  bottom: 0;

  background: #65c4fa;
  filter: blur(150px);
}

.ant-table-thead .ant-table-cell {
  background-color: #0f296d !important; /* Thay đổi màu nền header */
  color: #ffffff !important; /* Thay đổi màu chữ header */
}

.anticon-filter {
  background-color: #0f296d !important; /* Thay đổi màu nền header */
  color: #ffffff  !important;
}
.ant-table-column-sorter {
  color: #ffffff !important;
}

.ant-affix {
  z-index: 99;
}


